import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { AppContext } from '../../context/app'
import {
  create_share_url,
  getAudioTrailerUrl,
  getImage,
  getReleaseYear,
  getRemainingTime,
} from '../../utils/helper'
import './multiPartDetail.css'
import TrailerPlayer from '../player/trailerPlayer'
import useAxios from 'axios-hooks'
import { isEmpty } from 'lodash'
import { PARENT_DETAIL_CONTENT } from '../../utils/apiUrls'
import { ContentContext } from '../../context/content'
import { useTranslation } from 'react-i18next'
import CustomImage from '../../components/customImage/customImage'
import VideoPlaceholder from '../../assets/video-content.png'
import ShowMoreText from 'react-show-more-text'
import { withTheme } from 'theming'
import { SubscriptionContext } from '../../context/subscription'
import { useInterval } from '../../hooks/useInterval'
import AudioTrailerPlayer from '../../components/audioPlayer/audioTrailerPlayer'
import PlayIcon from '../../assets/imgComponents/playIcon'
import NewShareIcon from '../../assets/imgComponents/newShareIcon'
import NewAddToWatchList from '../../assets/imgComponents/newAddToWatchlist'
import NewAddToFavorite from '../../assets/imgComponents/newAddToFavorite'
import BriefcaseIcon from '../../assets/imgComponents/briefcaseIcon'
import CrownIcon from '../../assets/imgComponents/crownIcon'
import NewAddedToFavorite from '../../assets/imgComponents/newAddedToFavorite'
import NewAddedToWatchList from '../../assets/imgComponents/newAddedToWatchlist'
import ListenIcon from '../../assets/imgComponents/listenIcon'
import ProgressBar from '../../components/progressBar/progressBar'
import ShareModal from '../../components/shareModal/shareModal'
import MultipartDetailBottom from './multipartDetailBottom'
import CustomMetadata from '../../components/customMetadata/customMetadata'
import { Helmet } from 'react-helmet'

const NewMultipartDetail = (props) => {
  let { theme, data } = props

  const contentId = useParams().contentId
  const navigate = useNavigate()
  const { isCustomTemplate, isLogin, setLoader, setOpenLoginModal, setModalType, accountObj } =
    useContext(AppContext)
  const { setOpenSubscriptionModal, setSubscriptions, setRedirectionUrl } =
    useContext(SubscriptionContext)
  const { t } = useTranslation()
  const {
    isInFavoriteList,
    isInWatchList,
    addRemoveFavouriteList,
    addRemoveWatchList,
    getResumeTimestamp,
  } = useContext(ContentContext)
  const [detailObj, setDetailObj] = useState()
  const [childContentList, setChildContentList] = useState([])
  const [latestEpisode, setLatestEpisode] = useState({})
  const [tooltipHover, setTooltipHover] = useState('')
  const [shareModal, setShareModal] = useState(false)
  const [showMore, setShowMore] = useState(false)

  const [{ data: contentDetail, loading: contentLoading }, contentDetailCall] = useAxios({
    url: `${PARENT_DETAIL_CONTENT}${contentId}`,
    method: 'get',
  })

  useInterval(async () => {
    if (accountObj?.od_resource_allowances?.content_refresh) {
      await contentDetailCall()
    }
  }, 10000)

  useEffect(() => {
    contentDetailCall()
  }, [contentId])

  useEffect(() => {
    if (!isEmpty(childContentList)) {
      let tempLatest = {}
      for (let i = 0; i < childContentList.length; i++) {
        const element = childContentList[i]
        if (getResumeTimestamp(element.content_id)) {
          tempLatest = element
        }
      }
      setLatestEpisode({ ...tempLatest })
    }
  }, [childContentList])

  useEffect(() => {
    if (contentDetail && contentDetail.success) {
      const contentObj = contentDetail.data
      setDetailObj(contentObj.parent_content)
      setChildContentList(contentObj.child_content_list)
    }
  }, [contentDetail])

  useEffect(() => {
    setLoader(isEmpty(detailObj) && contentLoading)
  }, [contentLoading])

  const handleCategoryClick = (id, name) => {
    navigate(`/metadata/${id}`)
  }

  const handleSubCategory = (id, name) => {
    navigate(`/metadata/${id}`)
  }

  const purchaseBundle = () => {
    if (!isLogin) {
      setModalType('login')
      setOpenLoginModal(true)
    } else {
      let activeSub = [...detailObj.active_subscription.map((item) => item.id)]
      let activePPV = [...detailObj.active_ppv_subscription.map((item) => item.id)]
      setRedirectionUrl(window.location.pathname)
      setSubscriptions([...activeSub, ...activePPV])
      setOpenSubscriptionModal(true)
    }
  }

  const handleWatchNow = () => {
    if (!isLogin || !detailObj.is_restricted || !detailObj?.is_purchased_required) {
      if (accountObj?.od_resource_allowances?.player_page && !isEmpty(childContentList)) {
        if (!isEmpty(latestEpisode)) {
          navigate(`/series-watch/${latestEpisode.content_id}`)
        } else {
          navigate(`/series-watch/${childContentList[0].content_id}`)
        }
      }
    } else {
      purchaseBundle()
    }
  }

  const getButtonText = () => {
    if (!isLogin || !detailObj.is_restricted || !detailObj?.is_purchased_required) {
      if (detailObj?.asset_type === 'AUDIO') {
        return t('listen_now')
      }

      return !isEmpty(latestEpisode)
        ? `${
            getRemainingTime(
              latestEpisode?.duration_in_s,
              getResumeTimestamp(latestEpisode.content_id),
            )
              ? t('resume_episode')
              : t('watch_again_episode')
          } ${
            childContentList.findIndex((info) => info.content_id === latestEpisode.content_id) + 1
          }`
        : `${t('watch_episode')} 1`
    } else {
      return t('upgrade_plan')
    }
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <>
      {detailObj && (
        <>
          <Helmet>
            <meta property="og:title" content={detailObj?.title} />
            <meta property="og:description" content={detailObj?.description} />
            <meta
              property="og:image"
              content={getImage(accountObj?.cf_domain_name, detailObj.thumbnail)}
            />
          </Helmet>
          <div
            style={{
              background: getStyleValue('component_background', 'primary_color', theme.c1),
            }}
            className={`flex flex-col-reverse ${
              data?.aligned === 'RIGHT' ? 'xl:flex-row' : 'xl:flex-row-reverse'
            } xl:justify-between 2xl:px-24 xl:px-14 xl:pt-10 w-full`}
          >
            <div
              className={`z-10 content-detail-info-box xl:mt-0 mt-6 xl:px-0 md:px-10 px-4`}
              style={{ color: '#e3e2e2' }}
            >
              <div
                className={`flex overflow-hidden ${getStyleValue(
                  'sub_heading',
                  'font_format',
                  '',
                )}`}
                style={{
                  color: getStyleValue('sub_heading', 'color', '#bbbbbb'),
                  fontFamily: getStyleValue('sub_heading', 'font', ''),
                }}
              >
                {!isEmpty(detailObj?.content_rating) ? (
                  <div
                    className="flex items-center justify-center px-2 rounded"
                    style={{
                      background: `${getStyleValue('sub_heading', 'bg_color', '#000000')}50`,
                    }}
                  >
                    {detailObj?.content_rating?.icon?.s3_key && (
                      <div className="">
                        <img
                          src={getImage(
                            accountObj?.cf_domain_name,
                            detailObj?.content_rating?.icon?.s3_key,
                          )}
                          className="w-5 h-5"
                          alt="Rating"
                        />
                      </div>
                    )}
                    <p className="ml-1">{detailObj?.content_rating?.name}</p>
                  </div>
                ) : null}
                {detailObj?.release_time ? (
                  <p
                    style={{
                      background: `${getStyleValue('sub_heading', 'bg_color', '#000000')}50`,
                    }}
                    className={`${
                      !isEmpty(detailObj?.content_rating) ? 'ml-2 xl:ml-4 2xl:ml-8' : ''
                    } px-2 rounded`}
                  >
                    {getReleaseYear(detailObj.release_time)}
                  </p>
                ) : null}

                <div
                  className={` flex ${
                    isEmpty(detailObj?.content_rating) && !detailObj?.release_time
                      ? 'w-full'
                      : 'w-[60%]'
                  }`}
                >
                  {[...detailObj?.category].map((item, index) => {
                    return index < 2 ? (
                      <p
                        key={index}
                        onClick={() => handleCategoryClick(item.id, item.name)}
                        className={`cursor-pointer ${
                          isEmpty(detailObj?.content_rating) && !detailObj?.release_time && !index
                            ? ''
                            : 'ml-2 xl:ml-4 2xl:ml-8'
                        }  line-clamp-1 px-2 rounded`}
                        style={{
                          background: `${getStyleValue('sub_heading', 'bg_color', '#000000')}50`,
                        }}
                      >
                        {item.name}
                      </p>
                    ) : null
                  })}
                </div>
              </div>
              <p
                style={{
                  color: getStyleValue('content_title', 'color', ''),
                  fontFamily: getStyleValue('content_title', 'font', ''),
                  lineHeight: 'normal',
                }}
                className={`text-3xl md:text-[26px] 2xl:text-[38px] ${getStyleValue(
                  'content_title',
                  'font_format',
                  'font-semibold',
                )} mt-5`}
              >
                {detailObj?.title}
              </p>
              <div className={`flex items-start mt-5`}>
                <div className="">
                  <button
                    className="px-2.5 2xl:px-4 py-2 text-xs md:text-sm 2xl:text-lg focus:outline-none flex items-center"
                    style={{
                      color: getStyleValue('play_button', 'button_text', '#000'),
                      backgroundImage: `linear-gradient(to left , ${getStyleValue(
                        'play_button',
                        'background_color1',
                        '#bbfa33',
                      )}, ${getStyleValue('play_button', 'background_color2', '#bbfa33')})`,

                      // backgroundColor:getStyleValue('ratings_reviews', 'text1_color', '#fff') '#bbfa33',
                      borderRadius: '6px',
                    }}
                    onClick={() => {
                      // setResumeTime(detailObj?.resume_timestamp)
                      handleWatchNow()
                    }}
                  >
                    <div className="mr-2">
                      {detailObj?.asset_type === 'AUDIO' ? (
                        <ListenIcon
                          color={getStyleValue('play_button', 'play_icon_color', '#000')}
                        />
                      ) : (
                        <PlayIcon color={getStyleValue('play_button', 'play_icon_color', '#000')} />
                      )}
                    </div>{' '}
                    {getButtonText()}
                  </button>
                  {!isEmpty(latestEpisode) &&
                  getRemainingTime(
                    latestEpisode?.duration_in_s,
                    getResumeTimestamp(latestEpisode.content_id),
                  ) ? (
                    <div className="px-2.5 2xl:px-4">
                      <ProgressBar
                        bgColor={
                          getResumeTimestamp(latestEpisode.content_id) ? '#bbbbbb' : 'transparent'
                        }
                        barClass={'mb-2 mt-2'}
                        progressWidth={`${Number(
                          (getResumeTimestamp(latestEpisode.content_id) /
                            latestEpisode?.duration_in_s) *
                            100,
                        )}%`}
                        progressColor={
                          getResumeTimestamp(latestEpisode.content_id)
                            ? getStyleValue('play_button', 'background_color1', '#bbfa33')
                            : 'transparent'
                        }
                      />
                      <p className="italic text-xs text-center">
                        {getRemainingTime(
                          latestEpisode?.duration_in_s,
                          getResumeTimestamp(latestEpisode.content_id),
                        )}
                        {t('left')}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className={`flex  `}>
                  <div
                    style={{
                      background: `${getStyleValue(
                        'operational_icon',
                        'operational_icon_bg_color',
                        '#070707',
                      )}50`,
                    }}
                    className="flex px-4 py-3 items-center rounded-lg ml-4"
                  >
                    <div
                      className={`cursor-pointer relative`}
                      onClick={() => setShareModal(true)}
                      onMouseOver={() => setTooltipHover('SHARE')}
                      onMouseLeave={() => setTooltipHover('')}
                    >
                      <NewShareIcon
                        color={getStyleValue('operational_icon', 'operational_icon_color', '#fff')}
                        hoverColor={getStyleValue(
                          'operational_icon',
                          'operational_icon_hover_color',
                          '#bbfa33',
                        )}
                      />
                      {tooltipHover === 'SHARE' && (
                        <div
                          style={{
                            left: '15px',
                            top: '2px',
                            background: `${getStyleValue(
                              'operational_icon',
                              'operational_icon_bg_color',
                              '#070707',
                            )}`,
                            border: `1px solid ${getStyleValue(
                              'operational_icon',
                              'operational_icon_hover_color',
                              '#bbfa33',
                            )}`,
                          }}
                          className="hidden lg:block text-xs px-2.5 rounded absolute ml-3 z-10"
                        >
                          {t('share')}
                        </div>
                      )}
                    </div>
                    {isLogin && (
                      <div className="flex items-center justify-between ">
                        <div
                          className=" cursor-pointer ml-2 xl:ml-4 relative"
                          onClick={() => addRemoveWatchList(contentId)}
                          onMouseOver={() => setTooltipHover('WATCHLIST')}
                          onMouseLeave={() => setTooltipHover('')}
                        >
                          {isInWatchList(contentId) ? (
                            <NewAddedToWatchList
                              color={getStyleValue(
                                'operational_icon',
                                'operational_icon_color',
                                '#fff',
                              )}
                              hoverColor={getStyleValue(
                                'operational_icon',
                                'operational_icon_hover_color',
                                '#bbfa33',
                              )}
                            />
                          ) : (
                            <NewAddToWatchList
                              color={getStyleValue(
                                'operational_icon',
                                'operational_icon_color',
                                '#fff',
                              )}
                              hoverColor={getStyleValue(
                                'operational_icon',
                                'operational_icon_hover_color',
                                '#bbfa33',
                              )}
                            />
                          )}
                          {tooltipHover === 'WATCHLIST' && (
                            <div
                              style={{
                                left: '15px',
                                top: '0px',
                                background: `${getStyleValue(
                                  'operational_icon',
                                  'operational_icon_bg_color',
                                  '#070707',
                                )}`,
                                border: `1px solid ${getStyleValue(
                                  'operational_icon',
                                  'operational_icon_hover_color',
                                  '#bbfa33',
                                )}`,
                              }}
                              className="hidden lg:block text-xs px-2.5 rounded absolute ml-3 w-max z-10"
                            >
                              {isInWatchList(contentId)
                                ? t('remove_from_watchlist')
                                : t('add_to_watchlist')}
                            </div>
                          )}
                        </div>
                        <div
                          className="cursor-pointer ml-2 xl:ml-4 relative"
                          onClick={() => addRemoveFavouriteList(contentId)}
                          onMouseOver={() => setTooltipHover('FAVORITES')}
                          onMouseLeave={() => setTooltipHover('')}
                        >
                          {isInFavoriteList(contentId) ? (
                            <NewAddedToFavorite
                              color={getStyleValue(
                                'operational_icon',
                                'operational_icon_color',
                                '#fff',
                              )}
                              hoverColor={getStyleValue(
                                'operational_icon',
                                'operational_icon_hover_color',
                                '#bbfa33',
                              )}
                            />
                          ) : (
                            <NewAddToFavorite
                              color={getStyleValue(
                                'operational_icon',
                                'operational_icon_color',
                                '#fff',
                              )}
                              hoverColor={getStyleValue(
                                'operational_icon',
                                'operational_icon_hover_color',
                                '#bbfa33',
                              )}
                            />
                          )}
                          {tooltipHover === 'FAVORITES' && (
                            <div
                              style={{
                                left: '15px',
                                top: '0px',
                                background: `${getStyleValue(
                                  'operational_icon',
                                  'operational_icon_bg_color',
                                  '#070707',
                                )}`,
                                border: `1px solid ${getStyleValue(
                                  'operational_icon',
                                  'operational_icon_hover_color',
                                  '#bbfa33',
                                )}`,
                              }}
                              className="hidden lg:block text-xs px-2.5 rounded absolute ml-3 w-max z-10"
                            >
                              {isInFavoriteList(contentId)
                                ? t('remove_from_favorites')
                                : t('add_to_favorites')}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {detailObj.is_restricted && (
                    <div
                      style={{
                        background: `${getStyleValue(
                          'operational_icon',
                          'informative_icon_bg_color',
                          '#070707',
                        )}50`,
                      }}
                      className="px-4 py-3 ml-4 flex items-center rounded-lg justify-center relative"
                      onMouseOver={() => setTooltipHover('PREMIUM_CONTENT')}
                      onMouseLeave={() => setTooltipHover('')}
                    >
                      {detailObj.is_restricted && detailObj?.is_purchased_required ? (
                        <BriefcaseIcon
                          color={getStyleValue(
                            'operational_icon',
                            'informative_icon_color',
                            '#ffee00',
                          )}
                        />
                      ) : (
                        <CrownIcon
                          color={getStyleValue(
                            'operational_icon',
                            'informative_icon_color',
                            '#ffee00',
                          )}
                        />
                      )}

                      {tooltipHover === 'PREMIUM_CONTENT' && (
                        <div
                          style={{
                            left: '80%',
                            top: '30%',
                            background: `${getStyleValue(
                              'operational_icon',
                              'operational_icon_bg_color',
                              '#070707',
                            )}`,
                            border: `1px solid ${getStyleValue(
                              'operational_icon',
                              'operational_icon_hover_color',
                              '#bbfa33',
                            )}`,
                          }}
                          className="hidden lg:block text-xs px-2.5 rounded absolute ml-3 w-max z-10"
                        >
                          {detailObj.is_restricted && detailObj?.is_purchased_required
                            ? t('premium_content')
                            : t('premium_content_included')}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="2xl:mt-10">
                <div className="xl:max-h-[16vw] hidden-scrollbar-class w-full mt-5 ">
                  <p
                    style={{
                      color: getStyleValue('content_description', 'color', '#bbbbbb'),
                      fontFamily: getStyleValue('content_description', 'font', ''),
                    }}
                    className={`${getStyleValue('content_description', 'font_format', '')}`}
                  >
                    <ShowMoreText
                      lines={3}
                      more={
                        <span
                          style={{
                            color: getStyleValue('play_button', 'background_color1', '#bbfa33'),
                          }}
                        >
                          {t('show_more')}
                        </span>
                      }
                      less={
                        <span
                          style={{
                            color: getStyleValue('play_button', 'background_color1', '#bbfa33'),
                          }}
                        >
                          {t('show_less')}
                        </span>
                      }
                      className={` w-full `}
                      anchorClass={`text-sm cursor-pointer `}
                      expanded={showMore}
                      onClick={() => setShowMore((showMore) => !showMore)}
                      truncatedEndingComponent={'... '}
                    >
                      <p>{detailObj?.description}</p>
                    </ShowMoreText>
                  </p>

                  <div
                    style={{
                      color: getStyleValue('metadata_titles', 'color', '#bbbbbb'),
                      fontFamily: getStyleValue('metadata_titles', 'font', ''),
                    }}
                    className={`${getStyleValue('metadata_titles', 'font_format', '')}  mt-5`}
                  >
                    {Object.keys(detailObj.artists).map((artist, index) => (
                      <div className={'flex justify-between mt-2'} key={index}>
                        <p style={{ whiteSpace: 'nowrap' }} className={'text-sm lg:w-[29%]'}>
                          {artist}
                        </p>
                        <div
                          style={{
                            color: getStyleValue('metadata_values', 'color', '#bbfa33'),
                            fontFamily: getStyleValue('metadata_values', 'font', ''),
                          }}
                          className={`${getStyleValue(
                            'metadata_values',
                            'font_format',
                            'font-semibold',
                          )}   lg:w-[70%] flex flex-col  xl:flex-row`}
                        >
                          <p className=" text-right lg:text-left">
                            {detailObj.artists[artist].map((item, index) => (
                              <span
                                key={index}
                                onClick={() => navigate(`/artist/detail/${item.id}`)}
                              >
                                {index !== 0 && <span className={''}>{' , '}</span>}
                                <span className={'cursor-pointer hover:underline'}>
                                  {item.name}
                                </span>
                              </span>
                            ))}
                          </p>
                        </div>
                      </div>
                    ))}

                    {!isEmpty(detailObj.sub_category) && (
                      <div className={'flex justify-between mt-2'}>
                        <p style={{ whiteSpace: 'nowrap' }} className={' text-sm lg:w-[29%]'}>
                          {t('genre')}
                        </p>
                        <div
                          style={{
                            color: getStyleValue('metadata_values', 'color', '#bbfa33'),
                            fontFamily: getStyleValue('metadata_values', 'font', ''),
                          }}
                          className={`${getStyleValue(
                            'metadata_values',
                            'font_format',
                            'font-semibold',
                          )}   lg:w-[70%] flex flex-col  xl:flex-row`}
                        >
                          <p className=" text-right lg:text-left">
                            {detailObj.sub_category.map((item, index) => (
                              <span
                                key={index}
                                onClick={() => handleSubCategory(item.id, item.name)}
                              >
                                {index !== 0 && <span className={''}>{' , '}</span>}
                                <span className={'cursor-pointer hover:underline'}>
                                  {item.name}
                                </span>
                              </span>
                            ))}
                          </p>
                        </div>
                      </div>
                    )}
                    {!isEmpty(detailObj.venues) && (
                      <div className={'flex justify-between mt-2'}>
                        <p style={{ whiteSpace: 'nowrap' }} className={' text-sm  lg:w-[29%]'}>
                          {t('venue')}
                        </p>
                        <div
                          style={{
                            color: getStyleValue('metadata_values', 'color', '#bbfa33'),
                            fontFamily: getStyleValue('metadata_values', 'font', ''),
                          }}
                          className={`${getStyleValue(
                            'metadata_values',
                            'font_format',
                            'font-semibold',
                          )}   lg:w-[70%] flex flex-col  xl:flex-row`}
                        >
                          <p className=" text-right lg:text-left">
                            {detailObj.venues.map((item, index) => (
                              <span
                                key={index}
                                onClick={() => navigate(`/venue/detail/${item.id}`)}
                              >
                                {index !== 0 && <span className={''}>{' , '}</span>}
                                <span className={'cursor-pointer hover:underline'}>
                                  {item.name}
                                </span>
                              </span>
                            ))}
                          </p>
                        </div>
                      </div>
                    )}
                    {detailObj.custom_metadata_new ? (
                      <CustomMetadata
                        customMetadata={detailObj.custom_metadata_new}
                        valueColor={getStyleValue('metadata_values', 'color', '#bbfa33')}
                        valueFontFamily={getStyleValue('metadata_values', 'font', '')}
                        valueFontFormat={getStyleValue(
                          'metadata_values',
                          'font_format',
                          'font-semibold',
                        )}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              {/* {!isPlanExist() && (
                <button
                  className="px-4 py-1 mt-4 focus:outline-none"
                  style={{ color: '#fff', backgroundColor: '#1976d2', borderRadius: '6px' }}
                  onClick={purchaseBundle}
                >
                  {'Buy Bundle'}
                </button>
              )} */}
            </div>

            <div className="new-content-image  w-full">
              <div className="new-content-image-div relative xl:rounded-[20px] overflow-hidden ">
                {detailObj?.preview ? (
                  detailObj?.trailer_type === 'AUDIO' ? (
                    <AudioTrailerPlayer
                      url={getAudioTrailerUrl(accountObj?.cf_domain_name, detailObj.preview)}
                      detailObj={detailObj}
                      accountObj={accountObj}
                      isCustomTemplate={isCustomTemplate}
                      theme={theme}
                    />
                  ) : (
                    <TrailerPlayer
                      height={'calc(59vw / 1.7777)'}
                      preview={detailObj.preview}
                      poster={getImage(accountObj?.cf_domain_name, detailObj?.poster)}
                      quality={480}
                      type={'CONTENT'}
                      stream={detailObj.stream}
                      videoType={detailObj.video_type}
                    />
                  )
                ) : (
                  <CustomImage
                    imageUrl={getImage(
                      accountObj?.cf_domain_name,
                      detailObj?.asset_type === 'AUDIO' ? detailObj?.thumbnail : detailObj?.poster,
                    )}
                    loaderClass="new-content-loader"
                    imgAlt="content"
                    errorPlaceholder={VideoPlaceholder}
                  />
                )}
              </div>
            </div>
          </div>
          <MultipartDetailBottom
            detailObj={detailObj}
            childContentList={childContentList}
            theme={theme}
            removeMargin={true}
            data={data}
          />

          {shareModal && (
            <ShareModal
              onClose={() => setShareModal(false)}
              link={create_share_url()}
              detailObj={detailObj}
            />
          )}
        </>
      )}
    </>
  )
}

export default withTheme(NewMultipartDetail)
